import { config } from '@/configs';
import { DeskApplicationEnvironment } from '@/types/DeskApplicationEnvironment';

const environmentsWithMixpanelEnabled: string[] = [
  DeskApplicationEnvironment.DEMO,
  DeskApplicationEnvironment.PRODUCTION,
  // Uncomment the line below to run Mixpanel for local or staging
  // DeskApplicationEnvironment.STAGING,
  // DeskApplicationEnvironment.LOCAL,
];

export const isMixpanelEnabledOnCurrentEnvironment =
  environmentsWithMixpanelEnabled.includes(config.ENVIRONMENT);
