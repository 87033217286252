import { config } from '@/configs';
import { useAuthStore } from '@/stores/useAuthStore';

export const getHeaders = () => {
  const headers: Record<string, string> = {};
  const accessToken = useAuthStore().getAccessToken();

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  if (config.COMMIT_ID) {
    headers['x-commit-id'] = config.COMMIT_ID;
  }
  return headers;
};
