import { computed } from 'vue';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { PermissionAction } from '@/types/PermissionAction';
import { PermissionResource } from '@/types/PermissionResource';

export const useUsersPermissions = (shortname: string) => {
  const permissionStore = usePermissionsStore();

  const canReadUsers = computed(() =>
    permissionStore.can(
      PermissionAction.READ,
      PermissionResource.USERS,
      shortname,
    ),
  );

  const canWriteUsers = computed(() =>
    permissionStore.can(
      PermissionAction.WRITE,
      PermissionResource.USERS,
      shortname,
    ),
  );

  return {
    canReadUsers,
    canWriteUsers,
  };
};
