import { config } from '@/configs';
import { DeskApplicationEnvironment } from '@/types/DeskApplicationEnvironment';

const environmentsWithSentryEnabled: string[] = [
  DeskApplicationEnvironment.DEMO,
  DeskApplicationEnvironment.PRODUCTION,
  DeskApplicationEnvironment.STAGING,
  // Uncomment this line to run Sentry for local errors
  // DeskApplicationEnvironment.LOCAL,
];

export const isSentryEnabledOnCurrentEnvironment =
  environmentsWithSentryEnabled.includes(config.ENVIRONMENT);
