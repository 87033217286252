import { defineStore } from 'pinia';

const convertContentToIframeSrc = (output: string) =>
  `data:text/html;charset=utf-8,${encodeURIComponent(output)}`;

export const usePrintStore = defineStore('printStore', {
  state: () => ({ iframeSource: '' }),
  actions: {
    setPrintIframeContent(content: string) {
      this.iframeSource = convertContentToIframeSrc(content);
    },
    clearIframe() {
      this.iframeSource = '';
    },
  },
});
