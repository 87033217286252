import { defineStore } from 'pinia';
import { uniqueId } from '@/common/uniqueId';
import type { ToastType } from '@/components/shared/DeskToast/types';
import type { Toast } from '@/types/Toast';

const DEFAULT_TOAST_DURATION_MS = 5000;

export const useToastStore = defineStore({
  id: 'toast',
  state: () => {
    const toasts: Toast[] = [];
    return {
      toasts,
    };
  },
  actions: {
    toast(
      message: string,
      type: ToastType,
      durationMs: number = DEFAULT_TOAST_DURATION_MS,
      description?: string,
      additionalInformation?: string,
    ) {
      const newToast: Toast = {
        message,
        type,
        description,
        additionalInformation,
        id: uniqueId('toast'),
        dismiss: () => {},
      };
      const dismissFn = () => {
        this.toasts = this.toasts.filter((toast) => toast.id !== newToast.id);
      };
      newToast.dismiss = dismissFn;
      if (durationMs !== Infinity) {
        setTimeout(dismissFn, durationMs);
      }
      this.toasts.push(newToast);
      return newToast;
    },
    success(
      message: string,
      durationMs: number = DEFAULT_TOAST_DURATION_MS,
      description?: string,
      additionalInformation?: string,
    ) {
      return this.toast(
        message,
        'success',
        durationMs,
        description,
        additionalInformation,
      );
    },
    error(
      message: string,
      durationMs: number = DEFAULT_TOAST_DURATION_MS,
      description?: string,
      additionalInformation?: string,
    ) {
      return this.toast(
        message,
        'error',
        durationMs,
        description,
        additionalInformation,
      );
    },
    warning(
      message: string,
      durationMs: number = DEFAULT_TOAST_DURATION_MS,
      description?: string,
      additionalInformation?: string,
    ) {
      return this.toast(
        message,
        'warning',
        durationMs,
        description,
        additionalInformation,
      );
    },
    loading(
      message: string,
      durationMs: number = DEFAULT_TOAST_DURATION_MS,
      description?: string,
      additionalInformation?: string,
    ) {
      return this.toast(
        message,
        'loading',
        durationMs,
        description,
        additionalInformation,
      );
    },
  },
});
