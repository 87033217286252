import { createApp } from 'vue';
import '@fareharbor-com/beacon-vue/fonts/roboto';
import {
  useTheme,
  dashboardLightTheme,
} from '@fareharbor-com/beacon-vue/themes';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { createPinia } from 'pinia';
import { getApolloClient } from '@/common/getApolloClient';
import { initializeMixpanel } from '@/common/mixpanel/initializeMixpanel';
import { initializeSentry } from '@/common/sentry/initializeSentry';
import App from './App.vue';
import './assets/main.css';
import './assets/print.css';
import { router } from './router';
import { setGuardForRouter } from './router/guard';
import { setRouteErrorHandler } from './router/setRouteErrorHandler';

useTheme(dashboardLightTheme);

const pinia = createPinia();

setGuardForRouter(router);
setRouteErrorHandler(router);

const app = createApp(App);

// Sentry should be initialize as soon as we have an app
// So we can catch errors as early as possible
initializeSentry(app);

initializeMixpanel();

app.use(pinia);
app.provide(DefaultApolloClient, getApolloClient());
app.use(router);

app.mount('#app');

document.title = 'Desk';
