import { useToastStore } from '@/stores/useToastStore';
import type { ErrorResponse, ValidationErrorResponse } from '@/types';
import { Logger } from '../Logger';
import { APIError } from './APIError';

interface HandleThrownErrorConfig {
  error: unknown;
  log?: boolean;
  throw?: boolean;
  toastMessage?: string;
  toastDuration?: number;
}

export const handleThrownError = (config: HandleThrownErrorConfig) => {
  let serverError: ErrorResponse | ValidationErrorResponse | undefined;

  if (config.error instanceof APIError) {
    serverError = config.error.serverError;
  }

  if (config.toastMessage) {
    useToastStore().error(
      config.toastMessage,
      config.toastDuration ?? 5000,
      serverError?.message,
      serverError?.correlation_id,
    );
  }

  if (config.log) {
    Logger.error(config.error);
  }

  if (config.throw) {
    throw config.error;
  }
};
