<script setup lang="ts">
import { computed } from 'vue';
import { InformationCircleIcon } from '@heroicons/vue/24/solid';
import clsx from 'clsx';
import type { DeskAlertType } from './types';

interface DeskAlertProps {
  type?: DeskAlertType;
  icon?: boolean;
}

const props = withDefaults(defineProps<DeskAlertProps>(), {
  type: 'info',
  icon: true,
});

const baseAlertClasses = 'flex items-center p-3 text-sm rounded-lg';
const infoAlertClasses = 'bg-indigo-100';
const dangerAlertClasses = 'bg-red-50 text-red-800';
const successAlertClasses = 'bg-green-50 text-green-800';
const warningAlertClasses = 'bg-yellow-50 text-yellow-800';
const infoLightAlertClasses = 'bg-blue-50 text-blue-800';

const alertClasses = computed(() =>
  clsx(baseAlertClasses, {
    [infoAlertClasses]: props.type === 'info',
    [infoLightAlertClasses]: props.type === 'info-light',
    [dangerAlertClasses]: props.type === 'danger',
    [successAlertClasses]: props.type === 'success',
    [warningAlertClasses]: props.type === 'warning',
  }),
);

const baseIconClasses = 'w-5 h-5 mr-2';
const alertIconClasses = computed(() =>
  clsx(baseIconClasses, {
    'text-purple-700': props.type === 'info',
    'text-blue-700': props.type === 'info-light',
    'text-red-700': props.type === 'danger',
    'text-green-700': props.type === 'success',
    'text-yellow-700': props.type === 'warning',
  }),
);
</script>

<template>
  <div :class="alertClasses" role="alert" data-test-id="desk-alert">
    <span v-if="icon" name="icon" data-test-id="desk-alert-icon">
      <InformationCircleIcon class="w-5 h-5 mr-2" :class="alertIconClasses" />
    </span>
    <slot></slot>
  </div>
</template>
