import { getHeaders } from '@/common/getHeaders';
import { get } from '@/common/request';
import type { CompanySchema } from '@/types';
import type { GenericResponse } from '.';

type GetCompanyResponse = GenericResponse<CompanySchema>;

export const getCompany = async (
  shortname: string,
): Promise<GetCompanyResponse> => {
  const { response, data, error } = await get('/v1/companies/{shortname}', {
    params: {
      path: {
        shortname,
      },
    },
    headers: getHeaders(),
  });

  return {
    status: response.status,
    data,
    error,
  };
};
