import { getHeaders } from '@/common/getHeaders';
import { get } from '@/common/request';
import type { ActionsPerResource } from '@/types';
import type { GenericResponse } from '.';

type GetIamPermissionsResponse = GenericResponse<ActionsPerResource[]>;

export const getIamPermissions = async (
  shortname: string,
): Promise<GetIamPermissionsResponse> => {
  const { response, data, error } = await get(
    '/v1/companies/{shortname}/iam/permissions',
    {
      params: {
        path: {
          shortname,
        },
      },
      headers: getHeaders(),
    },
  );

  return {
    status: response.status,
    data,
    error,
  };
};
