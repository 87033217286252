import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { getHeaders } from '@/common/getHeaders';
import { fetchWithErrorHandler } from '@/common/request';
import { config } from '@/configs';

export const getApolloClient = () => {
  const httpLink = createHttpLink({
    uri: `${config.API_BASE_URL}/v1/graphql`,
    fetch: fetchWithErrorHandler,
    headers: getHeaders(),
  });

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ...getHeaders(),
    },
  }));
  const cache = new InMemoryCache();

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
  });

  return apolloClient;
};
