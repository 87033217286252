export interface EnvironmentConfig {
  readonly ENVIRONMENT: string;
  readonly API_BASE_URL: string;
  readonly COMMIT_ID?: string;
  readonly CLIENT_BASE_URL: string;
  readonly STRIPE_PUBLISHABLE_KEY: string;
  readonly SENTRY_DSN?: string;
  readonly SENTRY_SAMPLING_RATE?: number;
  readonly LOG_ERRORS?: boolean;
  readonly BUILD_NUMBER?: string;
  readonly MIXPANEL_TOKEN?: string;
}

export const config: EnvironmentConfig = {
  ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT,
  API_BASE_URL: import.meta.env.VITE_SERVER_URL,
  CLIENT_BASE_URL: import.meta.env.VITE_CLIENT_URL,
  STRIPE_PUBLISHABLE_KEY: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  COMMIT_ID: import.meta.env.VITE_COMMIT_ID,
  SENTRY_SAMPLING_RATE: import.meta.env.VITE_SENTRY_SAMPLING_RATE
    ? parseFloat(import.meta.env.VITE_SENTRY_SAMPLING_RATE)
    : undefined,
  LOG_ERRORS: !!import.meta.env.VITE_LOG_ERRORS,
  BUILD_NUMBER: import.meta.env.VITE_BUILD_NUMBER,
  MIXPANEL_TOKEN: import.meta.env.VITE_MIXPANEL_TOKEN,
};
