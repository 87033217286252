<script setup lang="ts">
import { computed, resolveComponent } from 'vue';
import type { RouteLocation } from 'vue-router';
import clsx from 'clsx';
import DeskSpinner from '@/components/shared/DeskSpinner.vue';

export type SupportedVariants =
  | 'default'
  | 'gray'
  | 'light'
  | 'green'
  | 'red'
  | 'yellow';

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const baseClasses = 'font-medium focus:ring-4';
const wideClasses = 'w-full block';

const disabledVariantClasses: Record<SupportedVariants, string> = {
  default: 'text-gray-100 bg-blue-400 cursor-not-allowed',
  light: 'text-white bg-gray-300 border border-gray-300 cursor-not-allowed',
  gray: 'text-black bg-gray-300 opacity-50 border border-gray-300 cursor-not-allowed',
  green: 'text-gray-100 focus:outline-none bg-green-300 cursor-not-allowed',
  red: 'text-gray-100 focus:outline-none bg-red-400 cursor-not-allowed',
  yellow: 'text-gray-100 focus:outline-none bg-yellow-300 cursor-not-allowed',
};

const variantClasses: Record<SupportedVariants, string> = {
  default: 'text-white bg-blue-700 hover:bg-blue-800 focus:ring-blue-300',
  gray: 'text-gray-800 bg-gray-50 border border-gray-300 focus:outline-none hover:bg-gray-300 focus:ring-gray-200',
  light:
    'text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-gray-200',
  green:
    'text-white focus:outline-none bg-green-700 hover:bg-green-800 focus:ring-green-300',
  red: 'text-white focus:outline-none bg-red-700 hover:bg-red-800 focus:ring-red-300',
  yellow:
    'text-white focus:outline-none bg-yellow-400 hover:bg-yellow-500 focus:ring-yellow-300',
};

const buttonSizeClasses: Record<ButtonSize, string> = {
  xs: 'text-xs px-2 py-1',
  sm: 'text-sm px-3 py-1.5',
  md: 'text-sm px-4 py-2',
  lg: 'text-base px-5 py-2.5',
  xl: 'text-base px-6 py-3',
};

const buttonEqualSpacingClasses: Record<ButtonSize, string> = {
  xs: 'text-xs p-1',
  sm: 'text-sm p-1.5',
  md: 'text-sm p-2',
  lg: 'text-base p-2.5',
  xl: 'text-base p-3',
};

interface DeskButtonProps {
  variant?: SupportedVariants;
  disabled?: boolean;
  wide?: boolean;
  loading?: boolean;
  type?: 'button' | 'submit';
  roundedFull?: boolean;
  equalSpacing?: boolean;
  size?: ButtonSize;
  to?: RouteLocation;
  href?: string;
}

const props = withDefaults(defineProps<DeskButtonProps>(), {
  variant: 'default',
  type: 'button',
  size: 'lg',
});

if (props.to && props.href) {
  throw new Error("to and href prop can't be provided at the same time");
}

const buttonClasses = computed(() =>
  clsx(baseClasses, {
    [buttonSizeClasses[props.size]]: !props.equalSpacing,
    [buttonEqualSpacingClasses[props.size]]: props.equalSpacing,
    [wideClasses]: props.wide,
    [disabledVariantClasses[props.variant]]: props.disabled,
    [variantClasses[props.variant]]: !props.disabled,
    'rounded-full': props.roundedFull,
    'rounded-lg': !props.roundedFull,
  }),
);

const getComponent = (
  isRouterLink: boolean,
  isAnchorLink: boolean,
): ReturnType<typeof resolveComponent> => {
  if (isRouterLink) {
    return resolveComponent('router-link');
  }
  if (isAnchorLink) {
    return 'a';
  }
  return 'button';
};

const buttonComponent = getComponent(!!props.to, !!props.href);

const attributes = computed(() => {
  if (props.href) {
    return {
      href: props.href,
    };
  }
  if (props.to) {
    return {
      to: props.to,
    };
  }
  return {
    type: props.type,
    disabled: props.disabled,
  };
});
</script>

<template>
  <component
    :is="buttonComponent"
    :class="buttonClasses"
    v-bind="attributes"
    data-test-id="desk-button"
  >
    <DeskSpinner v-if="loading" class="text-white fill-gray-300" />
    <slot v-else></slot>
  </component>
</template>

<style></style>
