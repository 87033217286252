import type { Router } from 'vue-router';
import { handlePageReload } from '@/common/handlePageReload';
import { config } from '@/configs';
import { DeskApplicationEnvironment } from '@/types/DeskApplicationEnvironment';

const routeErrorHandler = (): void => {
  if (
    config.ENVIRONMENT === DeskApplicationEnvironment.PRODUCTION ||
    config.ENVIRONMENT === DeskApplicationEnvironment.DEMO
  ) {
    handlePageReload();
  }
};

export const setRouteErrorHandler = (router: Router): void => {
  router.onError(routeErrorHandler);
};
