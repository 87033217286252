export const cookies = {
  /**
   * Returns a value from cookies
   * @param name {string} Name of the value from cookies
   * @returns {string|false} Return either the value from cookies
   * or false if the value is nto found
   */
  get(name: string): string | undefined {
    const cookieArr = document.cookie.split(';');
    for (let i = 0; i < cookieArr.length; i += 1) {
      const [cookieName, cookieValue] = cookieArr[i].split('=');
      if (name === cookieName.trim()) {
        return decodeURIComponent(cookieValue);
      }
    }
    return undefined;
  },
  /**
   * Sets a cookie value
   * @param name {string} Name (key) of the value to be stored in cookies
   * @param value {string} Value to be stored in cookies
   * @param daysToLive {number} Amount of days the cookies stays active (default: 30)
   */
  set(name: string, value: string, daysToLive: number = 30): void {
    let cookie = `${name}=${encodeURIComponent(value)}`;
    cookie += `; max-age=${daysToLive * 24 * 60 * 60}; path=/`;
    document.cookie = cookie;
  },
  /**
   * Removes a value from cookies
   * @param name {string} Name (key) of the value from the cookies that should be unset
   */
  unset(name: string): void {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  },
};
