<script setup lang="ts">
import { computed, ref } from 'vue';
import { RouterLink, useRoute } from 'vue-router';
import { ChevronDownIcon } from '@heroicons/vue/24/solid';
import { uniqueId } from '@/common/uniqueId';
import type { DropdownMenuItem } from './types';
import { useCompanyNavigationMenuItemClasses } from './useCompanyNavigationMenuItemClasses';

interface CompanyNavigationMenuDropdownItemProps {
  items: DropdownMenuItem[];
}

const props = defineProps<CompanyNavigationMenuDropdownItemProps>();

const route = useRoute();
const isActive = computed(() =>
  props.items.some((item) => route.path.startsWith(item.to.path)),
);

const dropdownToggleId = uniqueId('dropdown-toggle');
const isDropdownOpen = ref(false);

const openDropdown = () => {
  isDropdownOpen.value = true;
};

const closeDropdown = () => {
  isDropdownOpen.value = false;
};

const { dropdownMenuClasses, dropdownMenuItemClasses, activeRouterLinkClass } =
  useCompanyNavigationMenuItemClasses();
</script>

<template>
  <li class="mr-2 relative z-">
    <button
      :class="dropdownMenuClasses(isActive)"
      aria-haspopup="true"
      :aria-expanded="isDropdownOpen"
      :id="dropdownToggleId"
      @click="openDropdown()"
      @keyup.esc="closeDropdown"
    >
      <slot />
      <ChevronDownIcon class="w-4 h-4 inline" />
    </button>
    <div
      v-if="isDropdownOpen"
      @click.stop="closeDropdown"
      class="fixed top-0 left-0 w-full h-full z-40"
    ></div>
    <div v-if="isDropdownOpen" class="pt-3 absolute w-52 top-10 text-left z-50">
      <ul
        class="py-2 text-sm text-gray-700 font-normal bg-white rounded-lg shadow border border-gray-100"
        :aria-labelledby="dropdownToggleId"
        role="menu"
        @click.stop="closeDropdown"
      >
        <li v-for="(item, index) in items" :key="index" role="menuitem">
          <RouterLink
            :active-class="activeRouterLinkClass"
            :class="dropdownMenuItemClasses"
            :to="item.to"
          >
            {{ item.label }}
          </RouterLink>
        </li>
      </ul>
    </div>
  </li>
</template>
