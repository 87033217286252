import {
  addContextToMessage,
  type MessageContext,
} from '@/common/addContextToMessage';

export class ErrorWithContext extends Error {
  constructor(message: string, context: MessageContext = {}) {
    // Making a copy to avoid parameter reassign
    const messageContext = {
      ...context,
    };

    const shortname = localStorage.getItem('companyShortname');
    if (shortname) {
      messageContext.shortname = shortname;
    }
    const messageWithContext = addContextToMessage(message, messageContext);
    super(messageWithContext);
    this.name = 'ErrorWithContext';

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ErrorWithContext);
    }
  }
}
