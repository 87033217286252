import type { MessageContext } from '@/common/addContextToMessage';
import type { ErrorResponse, ValidationErrorResponse } from '@/types';
import { ErrorWithContext } from './ErrorWithContext';

export class APIError extends ErrorWithContext {
  serverError?: ErrorResponse | ValidationErrorResponse;

  constructor(
    message: string,
    context?: MessageContext,
    error?: ValidationErrorResponse | ErrorResponse,
  ) {
    const errorContext: Record<string, string> = {};

    if (error) {
      errorContext.errorMessage = error.message;
      errorContext.errorCode = error.code;
      errorContext.errorCorrelationId = error.correlation_id;
    }

    super(message, {
      ...context,
      ...errorContext,
    });
    this.name = 'APIError';
    this.serverError = error;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }
  }
}
