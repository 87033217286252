import mixpanel from 'mixpanel-browser';
import { Logger } from '@/common/Logger';
import { config } from '@/configs';
import { isMixpanelEnabledOnCurrentEnvironment } from './isMixpanelEnabledOnCurrentEnvironment';

export const initializeMixpanel = () => {
  if (!isMixpanelEnabledOnCurrentEnvironment) {
    return;
  }

  if (!config.MIXPANEL_TOKEN) {
    Logger.info('Missing MIXPANEL_TOKEN on environment', {
      environment: config.ENVIRONMENT,
    });
    return;
  }

  mixpanel.init(config.MIXPANEL_TOKEN, {
    track_pageview: true,
    persistence: 'localStorage',
  });
};
