import createClient from 'openapi-fetch';
import { config } from '@/configs';
import { useAuthStore } from '@/stores/useAuthStore';
import type { paths } from '@/types/APISchema';
import { handlePageReload } from './handlePageReload';

const handleResponse = (response: Response) => {
  if (response.status === 409) {
    handlePageReload();
  }
  if (response.status === 401) {
    const authStore = useAuthStore();
    authStore.logout();
    window.location.reload();
  }
  return response;
};

export const fetchWithErrorHandler = (
  url: RequestInfo | URL,
  init?: RequestInit,
) => fetch(url, init).then(handleResponse);

const querySerializer = (query: Record<string, unknown>): string => {
  const values: string[] = [];
  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((arrayItem) => values.push(`${key}=${arrayItem}`));
    } else {
      values.push(`${key}=${value}`);
    }
  });
  return values.join('&');
};

export const {
  GET: get,
  POST: post,
  PATCH: patch,
  PUT: put,
} = createClient<paths>({
  baseUrl: config.API_BASE_URL,
  fetch: fetchWithErrorHandler,
  querySerializer,
});
