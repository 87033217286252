import type { Permission } from '@/types/Permission';
import { PermissionAction } from '@/types/PermissionAction';
import { PermissionResource } from '@/types/PermissionResource';

export const readMaintenanceFormsPermission: Permission = [
  PermissionAction.READ,
  PermissionResource.MAINTENANCE_FORMS,
];

export const writeMaintenanceFormsPermission: Permission = [
  PermissionAction.WRITE,
  PermissionResource.MAINTENANCE_FORMS,
];

export const readOperationalReportsPermission: Permission = [
  PermissionAction.READ,
  PermissionResource.OPERATIONAL_REPORTS,
];

export const readFinancialReportsPermission: Permission = [
  PermissionAction.READ,
  PermissionResource.FINANCIAL_REPORTS,
];

export const readUsersPermission: Permission = [
  PermissionAction.READ,
  PermissionResource.USERS,
];

export const writeUsersPermission: Permission = [
  PermissionAction.WRITE,
  PermissionResource.USERS,
];

export const readCompanySettingsPermission: Permission = [
  PermissionAction.READ,
  PermissionResource.COMPANY_SETTINGS,
];
