<script setup lang="ts">
import { useRoute } from 'vue-router';
import { useCompanySettingsPermissions } from '@/composables/permissions/useCompanySettingsPermissions';
import { useMaintenanceFormsPermissions } from '@/composables/permissions/useMaintenanceFormsPermissions';
import { useReportsPermissions } from '@/composables/permissions/useReportsPermissions';
import { useUsersPermissions } from '@/composables/permissions/useUsersPermissions';
import { useAppStore } from '@/stores/useAppStore';
import CompanyNavigationDropdownMenuItem from './CompanyNavigationDropdownMenuItem.vue';
import CompanyNavigationMenuItem from './CompanyNavigationMenuItem.vue';
import { useCompanyNavigation } from './useCompanyNavigation';

const route = useRoute();
const shortname = route.params.shortname as string;

const { canReadFinancialReports, canReadOperationalReports } =
  useReportsPermissions(shortname);
const { canReadMaintenanceForms } = useMaintenanceFormsPermissions(shortname);
const { canReadUsers } = useUsersPermissions(shortname);
const { canReadCompanySettings } = useCompanySettingsPermissions(shortname);

const {
  clientsRoute,
  vendorSearchRoute,
  settingsDropdownItems,
  maintenanceDropdownItems,
  reportsRoute,
  activityLogRoute,
} = useCompanyNavigation(shortname);

const appStore = useAppStore();

const showProductsLink =
  appStore.isLocalEnvironment || appStore.isStagingEnvironment;
</script>

<template>
  <ul class="flex flex-wrap text-sm font-medium text-center text-gray-500">
    <CompanyNavigationMenuItem :to="vendorSearchRoute" v-if="showProductsLink">
      Products
    </CompanyNavigationMenuItem>
    <CompanyNavigationMenuItem :to="clientsRoute">
      Clients & vouchers
    </CompanyNavigationMenuItem>

    <CompanyNavigationMenuItem
      v-if="canReadFinancialReports || canReadOperationalReports"
      :to="reportsRoute"
    >
      Reports
    </CompanyNavigationMenuItem>

    <CompanyNavigationMenuItem :to="activityLogRoute">
      Activity log
    </CompanyNavigationMenuItem>

    <CompanyNavigationDropdownMenuItem
      v-if="canReadMaintenanceForms"
      :items="maintenanceDropdownItems"
    >
      Maintenance
    </CompanyNavigationDropdownMenuItem>

    <CompanyNavigationDropdownMenuItem
      :items="settingsDropdownItems"
      v-if="canReadUsers || canReadCompanySettings"
    >
      Settings
    </CompanyNavigationDropdownMenuItem>
  </ul>
</template>
