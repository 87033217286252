<script setup lang="ts">
import type { RouteLocation } from 'vue-router';
import { RouterLink } from 'vue-router';
import { useCompanyNavigationMenuItemClasses } from './useCompanyNavigationMenuItemClasses';

interface CompanyNavigationMenuItemProps {
  to: RouteLocation;
}

defineProps<CompanyNavigationMenuItemProps>();

const { menuItemClasses, activeRouterLinkClass } =
  useCompanyNavigationMenuItemClasses();
</script>

<template>
  <li class="mr-2">
    <RouterLink
      :active-class="activeRouterLinkClass"
      :class="menuItemClasses"
      :to="to"
    >
      <slot />
    </RouterLink>
  </li>
</template>
