import { defineStore } from 'pinia';
import { config } from '@/configs';
import { DeskApplicationEnvironment } from '@/types/DeskApplicationEnvironment';

interface AppStoreState {
  loading: boolean;
  error: boolean;
  errorMessage?: string;
  errorCorrelationId?: string;
  loadingMessage?: string;
}

export const useAppStore = defineStore('appStore', {
  state: (): AppStoreState => ({
    loading: false,
    error: false,
    loadingMessage: undefined,
    errorMessage: undefined,
    errorCorrelationId: undefined,
  }),

  actions: {
    setLoadingState(message: string) {
      this.loading = true;
      this.loadingMessage = message;
    },
    clearLoadingState() {
      this.loading = false;
      this.loadingMessage = undefined;
    },
    setErrorState(message: string, correlationId?: string) {
      this.clearLoadingState();
      this.error = true;
      this.errorCorrelationId = correlationId;
      this.errorMessage = message;
    },
  },
  getters: {
    isLoading: (state) => !state.error && state.loading,
    isError: (state) => state.error,
    isReady: (state) => !state.error && !state.loading,
    isDemoEnvironment: () =>
      config.ENVIRONMENT === DeskApplicationEnvironment.DEMO,
    isLocalEnvironment: () =>
      config.ENVIRONMENT === DeskApplicationEnvironment.LOCAL,
    isStagingEnvironment: () =>
      config.ENVIRONMENT === DeskApplicationEnvironment.STAGING,
    isProductionEnvironment: () =>
      config.ENVIRONMENT === DeskApplicationEnvironment.PRODUCTION,
  },
});
