<script setup lang="ts">
import { usePrintStore } from '@/stores/usePrintStore';

const printStore = usePrintStore();

const onIframeLoad = () => {
  window.print();
};
</script>

<template>
  <div class="desk-print" v-if="printStore.iframeSource">
    <iframe
      class="w-full h-fit print:fixed print:top-0 print:left-0 print:w-full print:h-full"
      :src="printStore.iframeSource"
      @load="onIframeLoad"
    ></iframe>
  </div>
</template>
