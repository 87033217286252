<template>
  <div
    class="w-full demo-banner-background-color text-sm text-white text-center p-2"
  >
    Demo Application - For Testing Purposes Only
  </div>
</template>

<style scoped>
.demo-banner-background-color {
  background-color: #c84801;
}
</style>
