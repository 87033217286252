import { computed } from 'vue';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { PermissionAction } from '@/types/PermissionAction';
import { PermissionResource } from '@/types/PermissionResource';

export const useCompanySettingsPermissions = (shortname: string) => {
  const permissionStore = usePermissionsStore();

  const canReadCompanySettings = computed(() =>
    permissionStore.can(
      PermissionAction.READ,
      PermissionResource.COMPANY_SETTINGS,
      shortname,
    ),
  );

  const canWriteCompanySettings = computed(() =>
    permissionStore.can(
      PermissionAction.WRITE,
      PermissionResource.COMPANY_SETTINGS,
      shortname,
    ),
  );

  return {
    canReadCompanySettings,
    canWriteCompanySettings,
  };
};
