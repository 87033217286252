import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from 'vue-router';
import {
  readFinancialReportsPermission,
  readMaintenanceFormsPermission,
  readUsersPermission,
  readOperationalReportsPermission,
  writeMaintenanceFormsPermission,
  writeUsersPermission,
  readCompanySettingsPermission,
} from '@/common/permissions';
import type { Permission } from '@/types/Permission';
import HomeView from '@/views/HomeView.vue';
import VersionView from '@/views/VersionView.vue';
import { companyBeforeEnter } from './companyBeforeEnter';

declare module 'vue-router' {
  interface RouteMeta {
    title?: string;
    isPublic?: boolean;
    requiredPermissions?: Permission[];
  }
}

const paymentsRoute: RouteRecordRaw = {
  path: 'payments',
  name: 'payments',
  meta: {
    title: 'Desk: Payments',
  },
  component: () => import('@/views/company/payments/PaymentsView.vue'),
};

const ratesRoute: RouteRecordRaw = {
  path: 'rates',
  children: [
    {
      path: '',
      name: 'rates',
      meta: {
        title: 'Desk: Rates',
        requiredPermissions: [readMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/rates/RatesView.vue'),
    },
    {
      path: 'create',
      name: 'create_rate',
      meta: {
        title: 'Desk: Create Rate',
        requiredPermissions: [writeMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/rates/CreateRateView.vue'),
    },
    {
      path: ':rateId',
      name: 'rate',
      meta: {
        title: 'Desk: Rate',
        requiredPermissions: [readMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/rates/RateView.vue'),
    },
  ],
};

const customerTypesRoute: RouteRecordRaw = {
  path: 'customer-types',
  children: [
    {
      path: '',
      name: 'customer_types',
      meta: {
        title: 'Desk: Customer Types',
        requiredPermissions: [readMaintenanceFormsPermission],
      },
      component: () =>
        import('@/views/company/customerTypes/CustomerTypesView.vue'),
    },
    {
      path: 'create',
      name: 'create_customer_type',
      meta: {
        title: 'Desk: Create Customer Type',
        requiredPermissions: [writeMaintenanceFormsPermission],
      },
      component: () =>
        import('@/views/company/customerTypes/CreateCustomerTypeView.vue'),
    },
    {
      path: ':customerTypeId',
      children: [
        {
          path: '',
          name: 'customer_type',
          meta: {
            title: 'Desk: Customer Type',
            requiredPermissions: [readMaintenanceFormsPermission],
          },
          component: () =>
            import('@/views/company/customerTypes/CustomerTypeView.vue'),
        },
        ratesRoute,
      ],
    },
  ],
};

const productsRoute: RouteRecordRaw = {
  path: 'products',
  children: [
    {
      path: '',
      name: 'products',
      meta: {
        title: 'Desk: Products',
        requiredPermissions: [readMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/products/ProductsView.vue'),
    },
    {
      path: 'create',
      name: 'create_product',
      meta: {
        title: 'Desk: Create Product',
        requiredPermissions: [writeMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/products/CreateProductView.vue'),
    },
    {
      path: ':productId',
      children: [
        {
          path: '',
          name: 'product',
          meta: {
            title: 'Desk: Product',
            requiredPermissions: [readMaintenanceFormsPermission],
          },
          component: () => import('@/views/company/products/ProductView.vue'),
        },
        customerTypesRoute,
      ],
    },
  ],
};

const vendorsRoute: RouteRecordRaw = {
  path: 'vendors',
  component: () => import('@/views/company/vendors/VendorsViewRoot.vue'),
  children: [
    {
      path: '',
      name: 'vendors',
      meta: {
        title: 'Desk: Vendors',
        requiredPermissions: [readMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/vendors/VendorsView.vue'),
    },
    {
      path: 'create',
      name: 'create_vendor',
      meta: {
        title: 'Desk: Create Vendor',
        requiredPermissions: [writeMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/vendors/CreateVendorView.vue'),
    },
    {
      path: ':vendorId',
      children: [
        {
          path: '',
          name: 'vendor',
          meta: {
            title: 'Desk: Vendor',
            requiredPermissions: [readMaintenanceFormsPermission],
          },
          component: () => import('@/views/company/vendors/VendorView.vue'),
        },
        productsRoute,
      ],
    },
  ],
};

const hotelsRoute: RouteRecordRaw = {
  path: 'hotels',
  component: () => import('@/views/company/hotels/HotelsViewRoot.vue'),
  children: [
    {
      path: '',
      name: 'hotels',
      meta: {
        title: 'Desk: Hotels',
        requiredPermissions: [readMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/hotels/HotelsView.vue'),
    },
    {
      path: 'create',
      name: 'create_hotel',
      meta: {
        title: 'Desk: Create Hotel',
        requiredPermissions: [writeMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/hotels/CreateHotelView.vue'),
    },
    {
      path: ':hotelId',
      children: [
        {
          path: '',
          name: 'hotel',
          meta: {
            title: 'Desk: Hotel',
            requiredPermissions: [readMaintenanceFormsPermission],
          },
          component: () => import('@/views/company/hotels/HotelView.vue'),
        },
      ],
    },
  ],
};

const locationsRoute: RouteRecordRaw = {
  path: 'locations',
  component: () => import('@/views/company/locations/LocationsViewRoot.vue'),
  children: [
    {
      path: '',
      name: 'locations',
      meta: {
        title: 'Desk: Locations',
        requiredPermissions: [readMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/locations/LocationsView.vue'),
    },
    {
      path: 'create',
      name: 'create_location',
      meta: {
        title: 'Desk: Create Location',
        requiredPermissions: [writeMaintenanceFormsPermission],
      },
      component: () =>
        import('@/views/company/locations/CreateLocationView.vue'),
    },
    {
      path: ':locationId',
      children: [
        {
          path: '',
          name: 'location',
          meta: {
            title: 'Desk: Location',
            requiredPermissions: [readMaintenanceFormsPermission],
          },
          component: () => import('@/views/company/locations/LocationView.vue'),
        },
      ],
    },
  ],
};

const sourcesRoute: RouteRecordRaw = {
  path: 'sources',
  component: () => import('@/views/company/sources/SourcesViewRoot.vue'),
  children: [
    {
      path: '',
      name: 'sources',
      meta: {
        title: 'Desk: Sources',
        requiredPermissions: [readMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/sources/SourcesView.vue'),
    },
    {
      path: 'create',
      name: 'create_source',
      meta: {
        title: 'Desk: Create Source',
        requiredPermissions: [writeMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/sources/CreateSourceView.vue'),
    },
    {
      path: ':sourceId',
      children: [
        {
          path: '',
          name: 'source',
          meta: {
            title: 'Desk: Source',
            requiredPermissions: [readMaintenanceFormsPermission],
          },
          component: () => import('@/views/company/sources/SourceView.vue'),
        },
      ],
    },
  ],
};

const desksRoute: RouteRecordRaw = {
  path: 'desks',
  component: () => import('@/views/company/desks/DesksViewRoot.vue'),
  children: [
    {
      path: '',
      name: 'desks',
      meta: {
        title: 'Desk: Desks',
        requiredPermissions: [readMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/desks/DesksView.vue'),
    },
    {
      path: 'create',
      name: 'create_desk',
      meta: {
        title: 'Desk: Create Desk',
        requiredPermissions: [writeMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/desks/CreateDeskView.vue'),
    },
    {
      path: ':deskId',
      children: [
        {
          path: '',
          name: 'desk',
          meta: {
            title: 'Desk: Desk',
            requiredPermissions: [readMaintenanceFormsPermission],
          },
          component: () => import('@/views/company/desks/DeskView.vue'),
        },
      ],
    },
  ],
};

const customFieldsRoute: RouteRecordRaw = {
  path: 'custom-fields',
  component: () =>
    import('@/views/company/customFields/CustomFieldsViewRoot.vue'),
  children: [
    {
      path: '',
      name: 'custom_fields',
      meta: {
        title: 'Desk: Custom Fields',
        requiredPermissions: [readMaintenanceFormsPermission],
      },
      component: () =>
        import('@/views/company/customFields/CustomFieldsView.vue'),
    },
    {
      path: 'create',
      name: 'create_custom_field',
      meta: {
        title: 'Desk: Create Custom Field',
        requiredPermissions: [writeMaintenanceFormsPermission],
      },
      component: () =>
        import('@/views/company/customFields/CreateCustomFieldView.vue'),
    },
    {
      path: ':customFieldId',
      children: [
        {
          path: '',
          name: 'custom_field',
          meta: {
            title: 'Desk: Custom Field',
            requiredPermissions: [readMaintenanceFormsPermission],
          },
          component: () =>
            import('@/views/company/customFields/CustomFieldView.vue'),
        },
      ],
    },
  ],
};

const payTypesRoute: RouteRecordRaw = {
  path: 'pay-types',
  component: () => import('@/views/company/payTypes/PayTypesViewRoot.vue'),
  children: [
    {
      path: '',
      name: 'pay_types',
      meta: {
        title: 'Desk: Pay Types',
        requiredPermissions: [readMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/payTypes/PayTypesView.vue'),
    },
    {
      path: 'create',
      name: 'create_pay_type',
      meta: {
        title: 'Desk: Create Pay Types',
        requiredPermissions: [writeMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/payTypes/CreatePayTypeView.vue'),
    },
    {
      path: ':payTypeId',
      children: [
        {
          path: '',
          name: 'pay_type',
          meta: {
            title: 'Desk: Pay Type',
            requiredPermissions: [readMaintenanceFormsPermission],
          },
          component: () => import('@/views/company/payTypes/PayTypeView.vue'),
        },
      ],
    },
  ],
};

const taxTypesRoute: RouteRecordRaw = {
  path: 'tax-types',
  component: () => import('@/views/company/taxTypes/TaxTypesViewRoot.vue'),
  children: [
    {
      path: '',
      name: 'tax_types',
      meta: {
        title: 'Desk: Tax Types',
        requiredPermissions: [readMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/taxTypes/TaxTypesView.vue'),
    },
    {
      path: 'create',
      name: 'create_tax_type',
      meta: {
        title: 'Desk: Create Tax Types',
        requiredPermissions: [writeMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/taxTypes/CreateTaxTypeView.vue'),
    },
    {
      path: ':taxTypeId',
      children: [
        {
          path: '',
          name: 'tax_type',
          meta: {
            title: 'Desk: Pay Type',
            requiredPermissions: [readMaintenanceFormsPermission],
          },
          component: () => import('@/views/company/taxTypes/TaxTypeView.vue'),
        },
      ],
    },
  ],
};

const refundReasonsRoute: RouteRecordRaw = {
  path: 'refund-reasons',
  component: () =>
    import('@/views/company/refundReasons/RefundReasonsViewRoot.vue'),
  children: [
    {
      path: '',
      name: 'refund_reasons',
      meta: {
        title: 'Desk: Refund Reasons',
        requiredPermissions: [readMaintenanceFormsPermission],
      },
      component: () =>
        import('@/views/company/refundReasons/RefundReasonsView.vue'),
    },
    {
      path: 'create',
      name: 'create_refund_reason',
      meta: {
        title: 'Desk: Create Refund Reason',
        requiredPermissions: [writeMaintenanceFormsPermission],
      },
      component: () =>
        import('@/views/company/refundReasons/CreateRefundReasonView.vue'),
    },
    {
      path: ':refundReasonId',
      children: [
        {
          path: '',
          name: 'refund_reason',
          meta: {
            title: 'Desk: Refund Reason',
            requiredPermissions: [readMaintenanceFormsPermission],
          },
          component: () =>
            import('@/views/company/refundReasons/RefundReasonView.vue'),
        },
      ],
    },
  ],
};

const usersRoute: RouteRecordRaw = {
  path: 'users',
  component: () => import('@/views/company/users/UsersViewRoot.vue'),
  children: [
    {
      path: '',
      name: 'users',
      meta: {
        title: 'Desk: Users',
        requiredPermissions: [readUsersPermission],
      },
      component: () => import('@/views/company/users/UsersView.vue'),
    },
    {
      path: 'create',
      name: 'create_user',
      meta: {
        title: 'Desk: Create User',
        requiredPermissions: [writeUsersPermission],
      },
      component: () => import('@/views/company/users/CreateUserView.vue'),
    },
    {
      path: ':userId',
      children: [
        {
          path: '',
          name: 'user',
          meta: {
            title: 'Desk: User',
            requiredPermissions: [readUsersPermission],
          },
          component: () => import('@/views/company/users/UserView.vue'),
        },
      ],
    },
  ],
};

const clientsRoute: RouteRecordRaw = {
  path: 'clients',
  component: () => import('@/views/company/clients/ClientsViewRoot.vue'),
  children: [
    {
      path: '',
      name: 'clients',
      meta: {
        title: 'Desk: Clients',
      },
      component: () => import('@/views/company/clients/ClientsView.vue'),
    },
    {
      path: 'new',
      name: 'create_client',
      meta: {
        title: 'Desk: Create Client',
      },
      component: () => import('@/views/company/clients/NewClientView.vue'),
    },
    {
      path: ':clientId',
      children: [
        {
          name: 'client',
          path: '',
          meta: {
            title: 'Desk: Client',
          },
          component: () => import('@/views/company/clients/ClientView.vue'),
        },
        {
          path: 'edit',
          name: 'edit_client',
          meta: {
            title: 'Desk: Edit Client',
          },
          component: () => import('@/views/company/clients/EditClientView.vue'),
        },
        {
          path: 'vouchers/create',
          name: 'create_voucher',
          meta: {
            title: 'Desk: Create Voucher',
          },
          component: () =>
            import('@/views/company/clients/CreateVoucherView.vue'),
        },
        {
          path: 'vouchers/:voucherId',
          name: 'voucher',
          meta: {
            title: 'Desk: Voucher',
          },
          component: () => import('@/views/company/clients/VoucherView.vue'),
        },
        paymentsRoute,
      ],
    },
  ],
};

const settingsRoute: RouteRecordRaw = {
  path: 'settings',
  name: 'company_settings',
  component: () => import('@/views/company/settings/SettingsView.vue'),
  children: [
    {
      path: 'company-information',
      name: 'settings_company_information',
      meta: {
        title: 'Desk: Company Information',
        requiredPermissions: [readCompanySettingsPermission],
      },
      component: () =>
        import('@/views/company/settings/SettingsCompanyInformationView.vue'),
    },
    {
      path: 'company-settings',
      name: 'settings_company_settings',
      meta: {
        title: 'Desk: Company Settings',
        requiredPermissions: [readCompanySettingsPermission],
      },
      component: () =>
        import('@/views/company/settings/CompanySettingsView.vue'),
    },
    {
      path: 'payments',
      name: 'settings_bank_and_payments',
      meta: {
        title: 'Desk: Bank & Payments',
        requiredPermissions: [readCompanySettingsPermission],
      },
      component: () =>
        import('@/views/company/settings/BankAndPaymentsSettingsView.vue'),
    },
  ],
};

const reportsRoute: RouteRecordRaw = {
  path: 'reports',
  component: () => import('@/views/company/reports/ReportsViewRoot.vue'),
  children: [
    {
      path: '',
      name: 'reports',
      meta: {
        title: 'Desk: Reports',
      },
      component: () => import('@/views/company/reports/ReportsView.vue'),
    },
    {
      name: 'agent_closeout',
      path: 'agent-closeout',
      meta: {
        title: 'Desk: Agent Closeout Report',
        requiredPermissions: [readOperationalReportsPermission],
      },
      component: () => import('@/views/company/reports/AgentCloseoutView.vue'),
    },
    {
      name: 'agent_commission',
      path: 'agent-commission',
      meta: {
        title: 'Desk: Agent Commission Report',
        requiredPermissions: [readOperationalReportsPermission],
      },
      component: () =>
        import('@/views/company/reports/AgentCommissionView.vue'),
    },
    {
      name: 'affiliate_commission',
      path: 'affiliate-commission',
      meta: {
        title: 'Desk: Affiliate Commission Report',
        requiredPermissions: [readOperationalReportsPermission],
      },
      component: () =>
        import('@/views/company/reports/AffiliateCommissionView.vue'),
    },
    {
      path: 'ar-ap',
      name: 'ar_ap',
      meta: {
        title: 'Desk: A/R-A/P Report',
        requiredPermissions: [readFinancialReportsPermission],
      },
      component: () => import('@/views/company/reports/ARAPView.vue'),
    },
    {
      path: 'cash-receipts',
      name: 'cash_receipts',
      meta: {
        title: 'Desk: Cash Receipts Report',
        requiredPermissions: [readFinancialReportsPermission],
      },
      component: () => import('@/views/company/reports/CashReceiptsView.vue'),
    },
    {
      path: 'sales-analysis',
      name: 'sales_analysis',
      meta: {
        title: 'Desk: Sales Analysis Report',
        requiredPermissions: [readFinancialReportsPermission],
      },
      component: () => import('@/views/company/reports/SalesAnalysisView.vue'),
    },
  ],
};

const activityLogRoute: RouteRecordRaw = {
  path: 'activity-log',
  name: 'activity_log',
  meta: {
    title: 'Desk: Activity Log',
  },
  component: () => import('@/views/company/ActivityLogView.vue'),
};

const vendorSearchRoute: RouteRecordRaw = {
  path: 'vendor-search',
  name: 'vendor_search',
  meta: {
    title: 'Desk: Vendor Search',
  },
  component: () => import('@/views/company/VendorSearchView.vue'),
};

const agentCommissionPoliciesRoute: RouteRecordRaw = {
  path: 'agent-commission-policies',
  component: () =>
    import(
      '@/views/company/agentCommissionPolicies/AgentCommissionPoliciesViewRoot.vue'
    ),
  children: [
    {
      path: '',
      name: 'agent-commission-policies',
      meta: {
        title: 'Desk: Agent Commission Policies',
        requiredPermissions: [readMaintenanceFormsPermission],
      },
      component: () =>
        import(
          '@/views/company/agentCommissionPolicies/AgentCommissionPoliciesView.vue'
        ),
    },
    {
      path: 'create',
      name: 'create-agent-commission-policy',
      meta: {
        title: 'Desk: Create Agent Commission Policy',
        requiredPermissions: [writeMaintenanceFormsPermission],
      },
      component: () =>
        import(
          '@/views/company/agentCommissionPolicies/CreateAgentCommissionPolicyView.vue'
        ),
    },
    {
      path: ':agentCommissionPolicyId',
      children: [
        {
          path: '',
          name: 'agent-commission-policy',
          meta: {
            title: 'Desk: Agent Commission Policy',
            requiredPermissions: [readMaintenanceFormsPermission],
          },
          component: () =>
            import(
              '@/views/company/agentCommissionPolicies/AgentCommissionPolicyView.vue'
            ),
        },
      ],
    },
  ],
};

const affiliatesRoute: RouteRecordRaw = {
  path: 'affiliates',
  component: () => import('@/views/company/affiliates/AffiliatesViewRoot.vue'),
  children: [
    {
      path: '',
      name: 'affiliates',
      meta: {
        title: 'Desk: Affiliates',
        requiredPermissions: [readMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/affiliates/AffiliatesView.vue'),
    },
    {
      path: 'create',
      name: 'create-affiliate',
      meta: {
        title: 'Desk: Create Affiliate',
        requiredPermissions: [writeMaintenanceFormsPermission],
      },
      component: () =>
        import('@/views/company/affiliates/CreateAffiliateView.vue'),
    },
    {
      path: ':affiliateId',
      name: 'affiliate',
      meta: {
        title: 'Desk: Affiliates',
        requiredPermissions: [readMaintenanceFormsPermission],
      },
      component: () => import('@/views/company/affiliates/AffiliateView.vue'),
    },
  ],
};

const companyRoute: RouteRecordRaw = {
  path: '/company',
  children: [
    {
      path: '',
      name: 'desk_select',
      meta: {
        title: 'Desk: Select Desk',
      },
      component: () => import('@/views/company/CompaniesView.vue'),
    },
    {
      path: ':shortname',
      name: 'company',
      meta: {
        title: 'Desk: Company',
      },
      beforeEnter: companyBeforeEnter,
      component: () => import('@/views/CompanyView.vue'),
      children: [
        {
          path: '',
          name: 'company_dashboard',
          meta: {
            title: 'Desk: Company Dashboard',
          },
          component: () => import('@/views/company/DashboardView.vue'),
        },
        vendorSearchRoute,
        activityLogRoute,
        settingsRoute,
        clientsRoute,
        vendorsRoute,
        hotelsRoute,
        locationsRoute,
        agentCommissionPoliciesRoute,
        affiliatesRoute,
        sourcesRoute,
        desksRoute,
        customFieldsRoute,
        payTypesRoute,
        refundReasonsRoute,
        usersRoute,
        taxTypesRoute,
        reportsRoute,
      ],
    },
  ],
};

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'home',
      meta: { title: 'Desk' },
      component: HomeView,
    },
    {
      path: '/login',
      name: 'login',
      meta: { title: 'Desk: Login', isPublic: true },
      component: () => import('@/views/LoginView.vue'),
    },
    {
      path: '/reset-password-request',
      name: 'reset_password_request',
      meta: { title: 'Desk: Reset your password', isPublic: true },
      component: () => import('@/views/ResetPasswordRequestView.vue'),
    },
    {
      path: '/reset-password',
      name: 'reset_password',
      meta: { title: 'Desk: Reset your password', isPublic: true },
      component: () => import('@/views/ResetPasswordView.vue'),
    },
    {
      path: '/version',
      name: 'version',
      meta: { title: 'Desk: Version', isPublic: true },
      component: VersionView,
    },
    {
      path: '/no-access',
      name: 'no_access',
      meta: { title: 'No Access' },
      component: () => import('@/views/NoAccessView.vue'),
    },

    companyRoute,
  ],
});
