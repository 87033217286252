<script setup lang="ts">
import DeskFlexbox from '@/components/shared/DeskFlexbox/DeskFlexbox.vue';
import DeskSpinner from '@/components/shared/DeskSpinner.vue';
import { useAppStore } from '@/stores/useAppStore';

const appStore = useAppStore();
</script>

<template>
  <DeskFlexbox
    direction="column"
    align-items="center"
    justify-content="center"
    gap="4"
    class="w-full h-screen"
  >
    <DeskSpinner class="fill-blue-600 h-9 w-9" />
    <span class="font-semibold text-lg">{{ appStore.loadingMessage }}</span>
  </DeskFlexbox>
</template>
