import { defineStore } from 'pinia';
import { cookies } from '@/common/cookies';
import type { SessionSchema } from '@/types';

interface AuthStorState {
  username?: string;
  session?: SessionSchema;
}

export const useAuthStore = defineStore({
  id: 'auth',
  state: (): AuthStorState => ({
    username: cookies.get('username'),
    session: undefined,
  }),
  getters: {
    accessToken: () => cookies.get('access_token'),
    isLoggedIn: (state) => !!(state.username && cookies.get('access_token')),
    isSessionLoaded: (state) => !!state.session,
  },
  actions: {
    getAccessToken() {
      return cookies.get('access_token');
    },
    logout() {
      cookies.unset('username');
      cookies.unset('access_token');
      localStorage.removeItem('deskId');
      localStorage.removeItem('deskName');
      localStorage.removeItem('companyShortname');
      this.$reset();
    },
  },
});
