import { computed } from 'vue';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { PermissionAction } from '@/types/PermissionAction';
import { PermissionResource } from '@/types/PermissionResource';

export const useReportsPermissions = (shortname: string) => {
  const permissionStore = usePermissionsStore();

  const canReadFinancialReports = computed(() =>
    permissionStore.can(
      PermissionAction.READ,
      PermissionResource.FINANCIAL_REPORTS,
      shortname,
    ),
  );

  const canWriteFinancialReports = computed(() =>
    permissionStore.can(
      PermissionAction.WRITE,
      PermissionResource.FINANCIAL_REPORTS,
      shortname,
    ),
  );

  const canReadOperationalReports = computed(() =>
    permissionStore.can(
      PermissionAction.READ,
      PermissionResource.OPERATIONAL_REPORTS,
      shortname,
    ),
  );

  const canWriteOperationalReports = computed(() =>
    permissionStore.can(
      PermissionAction.WRITE,
      PermissionResource.OPERATIONAL_REPORTS,
      shortname,
    ),
  );

  return {
    canReadFinancialReports,
    canWriteFinancialReports,
    canReadOperationalReports,
    canWriteOperationalReports,
  };
};
