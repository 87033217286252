import clsx from 'clsx';

export const useCompanyNavigationMenuItemClasses = () => {
  const baseClasses = 'p-3 rounded-lg hover:text-gray-600 hover:bg-gray-50';
  const menuItemClasses = clsx(baseClasses, 'inline-block');
  const dropdownMenuItemClasses = 'block px-4 py-2 hover:bg-gray-100';
  const activeRouterLinkClass =
    'text-blue-600 bg-gray-100 hover:!text-blue-600 hover:!bg-gray-100';

  const dropdownMenuClasses = (isActive: boolean) =>
    clsx(baseClasses, 'block', { [activeRouterLinkClass]: isActive });

  return {
    menuItemClasses,
    dropdownMenuClasses,
    dropdownMenuItemClasses,
    activeRouterLinkClass,
  };
};
