type ContextValue = string | number | boolean | null | undefined;

export type MessageContext = Record<string, ContextValue>;

const keyValuePairToContextString = (key: string, value: ContextValue) =>
  ` [${key}=${value}]`;

export const addContextToMessage = (
  message: string,
  context: MessageContext,
): string => {
  let messageWithContext = message;
  Object.entries(context).forEach(([key, value]) => {
    messageWithContext += keyValuePairToContextString(key, value);
  });

  return messageWithContext;
};
