<script setup lang="ts">
import { computed } from 'vue';
import { RouterLink, useRoute, useRouter } from 'vue-router';
import CompanyNavigation from '@/components/companyNavigation/CompanyNavigation.vue';
import DeskFlexbox from '@/components/shared/DeskFlexbox/DeskFlexbox.vue';
import { config } from '@/configs';
import { useAuthStore } from '@/stores/useAuthStore';
import { DeskApplicationEnvironment } from '@/types/DeskApplicationEnvironment';

const authStore = useAuthStore();
const route = useRoute();
const router = useRouter();

const showCompanyNavigation = computed(
  () => !!route.matched.find((matchedRoute) => matchedRoute.name === 'company'),
);

const handleLogout = () => {
  authStore.logout();
  router.push({
    name: 'login',
  });
};
</script>

<template>
  <DeskFlexbox justifyContent="space-between" class="bg-white h-12">
    <DeskFlexbox align-items="center">
      <RouterLink
        class="text-2xl self-center font-bold py-2 mr-4"
        :to="{ name: 'home' }"
      >
        Desk
        <span
          class="text-red-600"
          v-if="config.ENVIRONMENT === DeskApplicationEnvironment.DEMO"
        >
          DEMO
        </span>
      </RouterLink>
      <CompanyNavigation v-if="showCompanyNavigation" />
    </DeskFlexbox>
    <DeskFlexbox align-self="center">
      <button
        class="text-sm font-medium text-center text-gray-500 inline-block p-3 rounded-lg hover:text-gray-600 hover:bg-gray-50"
        v-if="authStore.isLoggedIn"
        @click="handleLogout"
      >
        Logout
      </button>
    </DeskFlexbox>
  </DeskFlexbox>
</template>
