import type { Router } from 'vue-router';
import { useAuthStore } from '@/stores/useAuthStore';

export const setGuardForRouter = (router: Router) => {
  // Disabling consistent-return here as the router expects no default return
  // eslint-disable-next-line consistent-return
  router.beforeEach(async (to) => {
    const authStore = useAuthStore();

    const isDeskSelected =
      localStorage.getItem('deskId') && localStorage.getItem('deskName');
    const selectedCompany = localStorage.getItem('companyShortname');

    if (!authStore.isLoggedIn && !to.meta.isPublic) {
      return { name: 'login' };
    }

    if (authStore.isLoggedIn && to.name === 'login') {
      return { name: 'desk_select' };
    }

    if (authStore.isLoggedIn && to.name === 'home') {
      return { name: 'desk_select' };
    }

    if (authStore.isLoggedIn && to.name === 'company_dashboard') {
      if (to.params.shortname === selectedCompany) {
        return {
          name: 'clients',
          params: to.params,
        };
      }
      return { name: 'desk_select' };
    }

    if (
      !isDeskSelected &&
      to.name !== 'desk_select' &&
      to.path.includes('/company')
    ) {
      return { name: 'desk_select' };
    }
  });

  router.afterEach(async (to) => {
    document.title = to.meta?.title ?? 'Desk';
  });
};
