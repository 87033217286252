import { computed } from 'vue';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { PermissionAction } from '@/types/PermissionAction';
import { PermissionResource } from '@/types/PermissionResource';

export const useMaintenanceFormsPermissions = (shortname: string) => {
  const permissionStore = usePermissionsStore();

  const canReadMaintenanceForms = computed(() =>
    permissionStore.can(
      PermissionAction.READ,
      PermissionResource.MAINTENANCE_FORMS,
      shortname,
    ),
  );

  const canWriteMaintenanceForms = computed(() =>
    permissionStore.can(
      PermissionAction.WRITE,
      PermissionResource.MAINTENANCE_FORMS,
      shortname,
    ),
  );

  return {
    canReadMaintenanceForms,
    canWriteMaintenanceForms,
  };
};
