<script setup lang="ts">
import DeskAlert from '@/components/shared/DeskAlert/DeskAlert.vue';
import DeskFlexbox from '@/components/shared/DeskFlexbox/DeskFlexbox.vue';
import DeskTitleSubtitle from '@/components/shared/DeskTitleSubtitle/DeskTitleSubtitle.vue';
import { useAppStore } from '@/stores/useAppStore';

const appStore = useAppStore();
</script>

<template>
  <DeskFlexbox
    direction="column"
    align-items="center"
    justify-content="center"
    gap="4"
    class="w-full h-screen"
  >
    <div>
      <DeskTitleSubtitle
        title="Something went wrong"
        subtitle="Please reload the page and contact support if this problem persists."
        class="mb-4"
      />
      <DeskAlert type="danger">
        <div>
          <p>{{ appStore.errorMessage }}</p>
          <small v-if="appStore.errorCorrelationId">
            {{ appStore.errorCorrelationId }}
          </small>
        </div>
      </DeskAlert>
    </div>
  </DeskFlexbox>
</template>
