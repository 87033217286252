import { computed, type ComputedRef } from 'vue';
import { useRouter } from 'vue-router';
import { useCompanySettingsPermissions } from '@/composables/permissions/useCompanySettingsPermissions';
import { useUsersPermissions } from '@/composables/permissions/useUsersPermissions';
import { useCompanyStore } from '@/stores/useCompanyStore';
import type { DropdownMenuItem } from './types';

export const useCompanyNavigation = (shortname: string) => {
  const router = useRouter();

  const { canReadUsers } = useUsersPermissions(shortname);
  const { canReadCompanySettings } = useCompanySettingsPermissions(shortname);
  const companyStore = useCompanyStore();

  const clientsRoute = router.resolve({
    name: 'clients',
    params: {
      shortname,
    },
  });

  const vendorSearchRoute = router.resolve({
    name: 'vendor_search',
    params: {
      shortname,
    },
  });

  const vendorsRoute = router.resolve({
    name: 'vendors',
    params: {
      shortname,
    },
  });

  const desksRoute = router.resolve({
    name: 'desks',
    params: {
      shortname,
    },
  });

  const hotelsRoute = router.resolve({
    name: 'hotels',
    params: {
      shortname,
    },
  });

  const locationsRoute = router.resolve({
    name: 'locations',
    params: {
      shortname,
    },
  });

  const payTypesRoute = router.resolve({
    name: 'pay_types',
    params: {
      shortname,
    },
  });

  const taxTypesRoute = router.resolve({
    name: 'tax_types',
    params: {
      shortname,
    },
  });

  const sourcesRoute = router.resolve({
    name: 'sources',
    params: {
      shortname,
    },
  });

  const customFieldsRoute = router.resolve({
    name: 'custom_fields',
    params: {
      shortname,
    },
  });

  const voucherRefundReasonsRoute = router.resolve({
    name: 'refund_reasons',
    params: {
      shortname,
    },
  });

  const usersRoute = router.resolve({
    name: 'users',
    params: {
      shortname,
    },
  });

  const companyInformationRoute = router.resolve({
    name: 'settings_company_information',
    params: {
      shortname,
    },
  });
  const companySettingsRoute = router.resolve({
    name: 'settings_company_settings',
    params: {
      shortname,
    },
  });

  const bankAndPaymentsSettingsRoute = router.resolve({
    name: 'settings_bank_and_payments',
    params: {
      shortname,
    },
  });

  const agentCommissionPoliciesRoute = router.resolve({
    name: 'agent-commission-policies',
    params: {
      shortname,
    },
  });

  const affiliateRoute = router.resolve({
    name: 'affiliates',
    params: {
      shortname,
    },
  });

  const settingsDropdownItems: ComputedRef<DropdownMenuItem[]> = computed(
    () => {
      const dropdownItems: DropdownMenuItem[] = [];

      if (canReadCompanySettings.value) {
        dropdownItems.push(
          {
            to: companyInformationRoute,
            label: 'Company Information',
          },
          {
            to: bankAndPaymentsSettingsRoute,
            label: 'Bank and Payments',
          },
        );

        dropdownItems.push({
          to: companySettingsRoute,
          label: 'Company Settings',
        });
      }

      if (canReadUsers.value) {
        dropdownItems.push({ to: usersRoute, label: 'Users' });
      }

      return dropdownItems;
    },
  );

  const maintenanceDropdownItems: ComputedRef<DropdownMenuItem[]> = computed(
    () => {
      const items: DropdownMenuItem[] = [
        { to: vendorsRoute, label: 'Vendors' },
        { to: sourcesRoute, label: 'Sources' },
        { to: payTypesRoute, label: 'Pay Types' },
        { to: taxTypesRoute, label: 'Tax Types' },
        { to: hotelsRoute, label: 'Hotels' },
        { to: desksRoute, label: 'Desks' },
        { to: locationsRoute, label: 'Locations' },
        { to: customFieldsRoute, label: 'Custom Fields' },
        { to: voucherRefundReasonsRoute, label: 'Refund Reasons' },
        {
          to: agentCommissionPoliciesRoute,
          label: 'Agent Commission Policies',
        },
      ];

      if (companyStore.isAffiliatesEnabled) {
        items.push({
          to: affiliateRoute,
          label: 'Affiliates',
        });
      }

      return items;
    },
  );

  const reportsRoute = router.resolve({
    name: 'reports',
    params: {
      shortname,
    },
  });

  const activityLogRoute = router.resolve({
    name: 'activity_log',
    params: {
      shortname,
    },
  });

  return {
    clientsRoute,
    vendorSearchRoute,
    settingsDropdownItems,
    maintenanceDropdownItems,
    reportsRoute,
    activityLogRoute,
  };
};
