import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { APIError } from '@/common/errors/APIError';
import { useAppStore } from '@/stores/useAppStore';
import { useCompanyStore } from '@/stores/useCompanyStore';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import type { ErrorResponse, ValidationErrorResponse } from '@/types';

export const companyBeforeEnter = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const permissionStore = usePermissionsStore();
  const companyStore = useCompanyStore();
  const appStore = useAppStore();
  const shortname = to.params.shortname as string;

  if (shortname !== localStorage.getItem('companyShortname')) {
    // preventing manually changing company from the url
    return next({ name: 'desk_select' });
  }

  let isDataLoaded = false;

  try {
    appStore.setLoadingState('Loading company information');
    await Promise.all([
      companyStore.loadCompany(shortname, { throwError: true }),
      permissionStore.loadPermissions(shortname, { throwError: true }),
    ]);

    isDataLoaded = true;
  } catch (error: unknown) {
    let serverError: ErrorResponse | ValidationErrorResponse | undefined;

    if (error instanceof APIError) {
      serverError = error.serverError;
    }
    const errorMessage = serverError?.message ?? 'Unexpected error occurred';

    appStore.setErrorState(errorMessage, serverError?.correlation_id);
  } finally {
    appStore.clearLoadingState();
  }

  if (!isDataLoaded) {
    return next(from);
  }

  const canVisitRoute = permissionStore.canVisitRoute(to);
  if (!canVisitRoute) {
    return next({ name: 'no_access' });
  }
  return next();
};
