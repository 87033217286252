<script setup lang="ts">
import DeskToast from '@/components/shared/DeskToast/DeskToast.vue';
import { useToastStore } from '@/stores/useToastStore';

const toastStore = useToastStore();
</script>

<template>
  <div
    class="w-96 h-full fixed bottom-4 z-50 grid gap content-end pointer-events-none left-1/2 -translate-x-1/2"
  >
    <DeskToast
      v-for="toast in toastStore.toasts"
      :key="toast.id"
      :type="toast.type"
      :dismiss="toast.dismiss"
    >
      <div class="text-sm font-normal">
        {{ toast.message }}
      </div>
      <div v-if="toast.description" class="text-xs">
        {{ toast.description }}
      </div>
      <div v-if="toast.additionalInformation" class="text-xs text-gray-400">
        {{ toast.additionalInformation }}
      </div>
    </DeskToast>
  </div>
</template>
